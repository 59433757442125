import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import Header from '../organisms/HeaderDark';
import Footer from '../organisms/FooterDark';
import CookieConsent from '../atoms/utility/CookieConsent';
import BackToTopButton from '../atoms/utility/BacktoTop';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import '@fortawesome/fontawesome-svg-core/styles.css';
import '../../styles/style.css';
import PartnersCarousel from '../molecules/PartnersCarousel';

function BaseLayout({
  children,
  bodyClasses,
  heroContent,
  heroImg,
  mobileHeroImg,
  heroAlt = '',
  footerHero,
  partnerImages,
  mainSectionClass = '',
  customHeaderClass = '',
}) {
  const location = useLocation(); // Get the current location

  const showCarousel =
    location.pathname === '/devon-bleibtrey' ||
    location.pathname === '/shop-hop';

  return (
    <>
      <ToastContainer />
      <div
        className={`${bodyClasses} relative overflow-hidden flex flex-col justify-between w-full h-full min-h-screen`}
      >
        <Header
          heroContent={heroContent}
          heroImg={heroImg}
          mobileHeroImg={mobileHeroImg}
          heroAlt={heroAlt}
          customHeaderClass={customHeaderClass}
        />
        {showCarousel && <PartnersCarousel images={partnerImages} />}{' '}
        <main
          className={
            mainSectionClass ||
            'max-w-7xl mx-auto px-4 sm:px-6 xl:px-0 z-20 relative flex-grow h-full w-full'
          }
        >
          {children}
        </main>
        <Footer footerHero={footerHero} />
      </div>
      <div className="relative z-50">
        <BackToTopButton />
      </div>
      <div className="relative z-40">
        <CookieConsent />
      </div>
    </>
  );
}

export default BaseLayout;
