import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import NavBar from '../molecules/NavBarFetchDark';
import { useLocation } from '@reach/router';
import { isHeroPage } from '../../utils/helpers/is-hero-page';

export default function Header({
                                 heroContent,
                                 heroImg,
                                 mobileHeroImg,
                                 heroAlt = '',
                                 customHeaderClass = '',
                               }) {
  const { pathname } = useLocation();
  const [mobileNavActive, setMobileNavActive] = useState(false);
  const headerClasses = `relative z-30 bg-black ${
    isHeroPage(pathname) ? 'h-screen sm:h-[450px] lg:h-[49vw]' : 'h-16'
  }`;

  return (
    <header className={customHeaderClass || headerClasses}>
      <>
        {/* Desktop Hero Image */}
       {!!heroImg && <div className='absolute hidden sm:block z-10 h-full w-full pt-10 sm:pt-0 overflow-hidden'>
          <GatsbyImage
            image={getImage(heroImg)}
            style={{ height: '100%', objectFit: 'cover' }}
            alt={heroAlt}
          />
        </div>}
        {/* Mobile Hero Image */}
       {!!mobileHeroImg && <div className='block sm:hidden absolute z-10 h-full w-full pt-0 overflow-hidden'>
          <GatsbyImage
            image={getImage(mobileHeroImg)}
            style={{ height: '100%', objectFit: 'cover' }}
            alt={heroAlt}
          />
        </div>}
      </>
      {/* Desktop Background Gradient */}
      {isHeroPage(pathname) && !!heroImg && (
        <>
          <div className='hidden sm:block absolute z-20 h-full w-full'>
            <div className='bg-header-gradient w-full h-full relative'></div>
            {/* <div className="bg-header-gradient w-1/6 h-full absolute top-[-50%] right-0 rotate-[120deg]"></div> */}
          </div>
          {/* Mobile Background Gradient */}
          <div className='absolute sm:hidden z-20 top-0 bg-grey opacity-[0.6] h-full w-full'></div>
        </>
      )}
      {/* NavBar*/}
      <div className='relative z-40'>
        <NavBar
          setMobileNavActive={setMobileNavActive}
          mobileNavActive={mobileNavActive}
        />
      </div>
      {/* Hero*/}
      {heroContent && (
        <div className='relative z-30 max-w-7xl mx-auto h-full px-4 sm:px-6 xl:px-0 sm:pt-0'>
          <div
            className='sm:absolute mx-auto top-[35%] w-full sm:top-[18%] md:top-[18%] lg:top-[25%] xl:top-[25%] pt-[70%] sm:pt-0 py-10 sm:py-0 text-left'>
            <div className='mx-auto'>{heroContent()}</div>
          </div>
        </div>
      )}
    </header>
  );
}
