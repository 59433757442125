import React from 'react';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router';
import MobileMenu from '../atoms/navigation/MobileMenu';
import Hamburger from '../atoms/navigation/Hamburger';
import FlockxByFetchLogo from '../atoms/icons/flockxByFetch';

const baseLinks = [
  {
    label: 'Developers',
    url: '/developer',
  },
  {
    label: 'Blog',
    url: '/blog',
  },
  {
    label: 'Team',
    url: '/team',
  },
];

const docsLinks = [
  {
    label: 'Overview',
    url: '/docs',
  },
  {
    label: 'Platform',
    url: '/docs/platform',
  },
  {
    label: 'API',
    url: '/docs/api',
  },
  {
    label: 'Glossary',
    url: '/docs/glossary',
  },
  {
    label: 'Release Notes',
    url: '/docs/release-notes',
  },
];

export default function NavBar({
  navConfig,
  mobileNavActive,
  setMobileNavActive,
}) {
  const { pathname } = useLocation();
  const navLinkStyles =
    'px-3 rounded-md font-medium underline-offset-1 hover:underline';
  const isDocs = navConfig === 'docs';
  let navLinks;

  navLinks = isDocs ? docsLinks : baseLinks;

  const handleMobileMenuOnClick = () => {
    setMobileNavActive(!mobileNavActive);
  };

  return (
    <nav className={`absolute top-0 w-full z-40 darker-grey`}>
      <div className="max-w-7xl md:max-w-5xl lg:max-w-7xl mx-auto px-4 sm:px-6 xl:px-0 py-6 sm:py-2">
        <div className="relative flex items-center justify-between h-16">
          <div className="sm:hidden absolute inset-y-0 right-0 flex items-center ">
            <Hamburger
              toggle={mobileNavActive}
              onClickHandler={handleMobileMenuOnClick}
            />
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-between">
          <div className="flex-shrink-0 flex items-center mr-auto">
            {pathname !== '/devon-bleibtrey' && (
              <Link to="/">
                <FlockxByFetchLogo className="h-12 w-auto logo-grey dark-version" />
              </Link>
            )}
          </div>
            <div className="hidden sm:flex items-center sm:ml-6 text-white">
              <div className="flex space-x-2 lg:space-x-4 items-center justify-center">
                {navLinks.map((navLink, index) => {
                  if (navLink.externalLink)
                    return (
                      <a
                        key={index}
                        href={navLink.url}
                        className={`${navLinkStyles}`}
                        aria-current="page"
                        id={navLink.id ? navLink.id : ''}
                      >
                        {navLink.label}
                      </a>
                    );

                  return (
                    <Link
                      key={index}
                      to={navLink.url}
                      className={`${navLinkStyles}`}
                      aria-current="page"
                    >
                      {navLink.label}
                    </Link>
                  );
                })}
                {/* <a
                  href={
                    'https://app.flockx.io?utm_source=website&utm_medium=navigation&utm_campaign=website_cta&utm_id=platform_8_33b'
                  }
                  className={`${navLinkStyles} pr-0 hover:no-underline inline-block group`}
                  aria-current="page"
                  id={'find-activities-web-app'}
                >
                  <div className="hidden md:flex items-center justify-center border rounded-3xl border-white py-2 px-4 pl-5 bg-default bg-opacity-0 group-hover:bg-opacity-100 transition">
                    Find Activities
                    <div className="relative h-4 w-4">
                      <ChevronRightIcon className="h-4 w-4 ml-1 group-hover:opacity-0 opacity-100 transition ease-linear absolute inset-0 group-hover:left-[2px]" />
                      <ArrowSmRightIcon className="h-4 w-4 ml-1 group-hover:opacity-100 opacity-0 transition ease-linear absolute inset-0" />
                    </div>
                  </div>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {mobileNavActive && (
        <MobileMenu
          toggle={mobileNavActive}
          navLinks={navLinks}
          setMobileNavActive={setMobileNavActive}
        />
      )}
    </nav>
  );
}
