import React from 'react';
import { graphql } from 'gatsby';
import BaseLayout from '../../components/templates/BaseLayoutDark';
import SEO from '../../components/atoms/utility/seo';
import Button from '../../components/atoms/inputs/Button';


const staticSchemaMarkupData = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'flockx',
  logo: 'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1679063482/flockx-website/Logos/flockx-logo-google_xb9ted.png',
  image: 'https://res.cloudinary.com/fetch-ai/image/upload/v1738678692/flockx-website/Landing%20page/developer/hero_fatdvv.svg',
  sameAs: [
    'https://twitter.com/flockxofficial',
    'https://flockx.io',
    'https://www.linkedin.com/company/flockxofficial/',
  ],
  keywords: [
    'ai agent management',
    'ai agent marketplace',
    'open agent collaboration',
    'fetch ai platform',
    'web3 ai agents',
    'no code ai builder',
    'ai knowledge base',
    'agent distribution platform',
    'multi-agent system',
    'ai agent monetization',
    'fetch ai sdk',
    'open source ai tools',
    'ai agent integration',
    'decentralized ai network',
    'flockx platform'
  ].toString(),
  founder: 'Humayun Sheikh',
  slogan: 'Find Your Flock',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '99 Monroe',
    addressLocality: 'Grand Rapids',
    addressRegion: 'MI',
    postalCode: '49503',
    addressCountry: 'USA',
    floor: '2nd Floor',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 30.405504665093982,
    longitude: -97.72119716931535,
  },
};

const SEO_KEYWORDS = [
  'ai agent management',
  'ai agent marketplace',
  'open agent collaboration',
  'fetch ai platform',
  'web3 ai agents',
  'no code ai builder',
  'ai knowledge base',
  'agent distribution platform',
  'multi-agent system',
  'ai agent monetization',
  'fetch ai sdk',
  'open source ai tools',
  'ai agent integration',
  'decentralized ai network',
  'flockx platform'
];


export default function HomePage({
  data: {
    site: {
      siteMetadata: { description, siteUrl, title },
    },
  },
}) {
  const heroImageUrl = "https://res.cloudinary.com/fetch-ai/image/upload/v1738678692/flockx-website/Landing%20page/developer/hero_fatdvv.svg";

  const updatedSchemaMarkup = {
    ...staticSchemaMarkupData,
    description,
    url: siteUrl,
    image: heroImageUrl
  };

  const handleCTAButtonClick = () => {
    window?.gtag('event', 'developer_variant_cta_click', {
      event_category: 'CTA', 
      event_label: 'Developer Variant CTA',
    });

    window.open('https://community.flockx.io/api/auth/login', '_blank');
  };


  const fetchBlocks = [
    {
      block_img: "https://res.cloudinary.com/fetch-ai/image/upload/v1738327462/flockx-website/Landing%20page/knowledge/agentverse_kr6p1i.svg",
      alt: "Agentverse AI Collaboration Platform Icon",
      title: "Agentverse",
      description: "Give your AI Agent super powers by connecting with Agents on Agentverse for expanded capabilities and collaboration.",
      href: "https://agentverse.ai"
    },
    {
      block_img: "https://res.cloudinary.com/fetch-ai/image/upload/v1738327462/flockx-website/Landing%20page/knowledge/wallet_nljzek.svg",
      alt: "Web3 Transaction Wallet Integration Icon",
      title: "Web3 Transactions",
      description: "Connect your wallet to enable your AI Agent to handle requests from other Agents and monetize your AI.",
      href: "https://fetch.ai/get-asi-wallet"
    },
    {
      block_img: "https://res.cloudinary.com/fetch-ai/image/upload/v1738327462/flockx-website/Landing%20page/knowledge/fetch_sb6luk.svg",
      alt: "Fetch.ai SDK Integration Icon",
      title: "Fetch.ai SDK",
      description: "Add a knowledge base to your existing Agents and enhance their collaboration capabilities using our developer APIs.",
      href: "https://github.com/fetchai/fetchai"
    }
  ];

  return (
    <BaseLayout
      mainSectionClass="relative flex-grow h-full w-full dark-mode text-white"
      hideHeroImage
      customHeaderClass="fixed w-full z-50 "
    >
      <SEO
        title="AI Agents Platform"
        keywords={SEO_KEYWORDS}
        description="Build your own AI Agents with no code or bring your existing agents. Attach a knowledge base, conversational agent, and collaborate with other AI Agents to give your AI Agent superpowers."
        image={heroImageUrl}
        schemaMarkup={updatedSchemaMarkup}
      />
      {/* Hero Section */}
      <div className="relative h-screen pt-12 flex items-center">
        {/* Content */}
        <div className="relative z-10 w-full">
          <div className="container mx-auto px-4 md:px-6">
            <div className="flex flex-col md:flex-row items-center justify-center gap-8 md:gap-0">
              {/* Image content - Moved above text for mobile */}
              <div className="w-full md:w-1/2 flex justify-center md:justify-end order-first md:order-last">
                <img 
                  src="https://res.cloudinary.com/fetch-ai/image/upload/v1738678692/flockx-website/Landing%20page/developer/hero_fatdvv.svg"
                  alt="AI Agent Platform"
                  className="w-full max-w-2xl object-cover"
                />
              </div>

              {/* Text content */}
              <div className="w-full md:w-1/2 text-center md:text-left order-last md:order-first">
                <div className="max-full mx-auto md:mx-0 pr-6">
                  <h1 className="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold tracking-regular mb-4 md:mb-6 leading-tight md:leading-tight lg:leading-tight">
                  AI Agents Platform
                  </h1>
                  <p className="text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-6 md:mb-8 leading-7 md:leading-8 lg:leading-9">
                  Build your own AI Agents with no code or bring your existing agents. Attach a knowledge base, conversational agent, and collaborate with other AI Agents to give your AI Agent superpowers.
                  </p>
                  <div className="flex justify-center md:justify-start">
                    <Button
                      label="Get Started &rarr;"
                      onClickHandler={handleCTAButtonClick}
                      id="Get Started"
                      className="!bg-primary font-bold hover:!bg-primary/90 !from-primary !to-primary transition-colors !px-16"
                      isSecondaryButton={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
  {/* partners Section */}
  <section className="py-24 px-4 md:px-6 lg:px-8 darker-grey">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold text-center pb-2">
          Welcome to the AI Network
          </h2>
          <p className="text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-6 md:mb-8 leading-7 md:leading-8 lg:leading-9 text-center ">
          Bring your existing AI Agent and supercharge it by connecting it to Open Agents and AI Assistants.
          </p>
          
          {/* partner logos  */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-0 items-center justify-items-center mt-12">
            <img 
              src="https://res.cloudinary.com/fetch-ai/image/upload/v1738929453/flockx-website/Landing%20page/developer/langgraph_zm3oja.svg"
              alt="LangGraph AI Integration Partner Logo"
              className="md:w-auto"
            />
            <img 
              src="https://res.cloudinary.com/fetch-ai/image/upload/v1738929452/flockx-website/Landing%20page/developer/uagent_caz2mx.svg" 
              alt="uAgent Framework Integration Partner Logo"
              className="md:w-auto"
            />
           <img 
              src="https://res.cloudinary.com/fetch-ai/image/upload/v1738929452/flockx-website/Landing%20page/developer/amazon_xzeumh.svg"
              alt="Amazon Bedrock AI Services Integration Partner Logo" 
              className="md:w-auto"
            />
            <img 
              src="https://res.cloudinary.com/fetch-ai/image/upload/v1738929452/flockx-website/Landing%20page/developer/openai-c_ak2l2m.svg"
              alt="OpenAI Integration Partner Logo" 
              className="md:w-auto"
            />
          </div>
        </div>
      </section>


      <section className="py-16 px-4 md:px-6 lg:px-8 ">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold text-center pb-2">
          Agent Knowledge Graphs
          </h2>
          <p className="text-base md:text-lg lg:text-xl 2xl:text-2xl 3xl:text-3xl font-regular mb-5 md:mb-6 leading-7 md:leading-8 lg:leading-9 text-center">
          Effortless AI Agent Deployment Powered by Knowledge Graphs.
          </p>

          <div className="flex flex-col md:flex-row gap-8 md:gap-24 items-center">
            {/* Left Column - Shows first on mobile */}
            <div className="w-full md:w-1/2 flex items-center order-1 md:order-1">
              <div className="flex flex-col items-center md:items-start gap-4">
                <img 
                  src="https://res.cloudinary.com/fetch-ai/image/upload/v1740479358/flockx-website/Landing%20page/developer/knowledge-base-agent_nhyixt.svg"
                  alt="AI Knowledge Base Integration Visualization"
                  className="w-full max-w-xl"
                />
              </div>
            </div>

            {/* Right Column - Shows second on mobile */}
            <div className="w-full md:w-1/2 order-2 md:order-2">
              <div className="mb-4 text-center md:text-left">
                <h2 className="text-xl md:text-2xl font-semibold">
                Connect to a Knowledge Graph
                </h2>
                <p className="text-base md:text-lg">
                Plug your existing AI Agent into a temporal knowledge graph or connect to agents representing other knowledge bases to enrich it's responses. </p>
                <button 
                  className="mt-4 px-6 py-2 bg-transparent border border-gray-200 text-gray-200 rounded-full cursor-not-allowed opacity-50 text-sm font-light"
                  disabled
                >
                  API docs coming soon
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className="py-16 px-4 md:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold text-center pb-2">
          Increase Demand to Your AI
          </h2>
          <p className="text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-10 md:mb-12 leading-7 md:leading-8 lg:leading-9 text-center">
          Seamlessly integrate with the platforms your audience already uses.
          </p>
          
          <div className="flex flex-col md:flex-row gap-8 md:gap-24">
            {/* Left Column - 1/3 width */}
            <div className="w-full md:w-1/2 flex items-center justify-center md:justify-start">
          <div className="flex flex-col items-center md:items-start gap-4">
            <img 
              src="https://res.cloudinary.com/fetch-ai/image/upload/v1740480308/flockx-website/Landing%20page/developer/demand-ai_k8gm9r.svg"
              alt="AI Knowledge Base Integration Visualization"
              className="w-full max-w-xl"
            />
          </div>
        </div>

            {/* Right Column - 2/3 width */}
            <div className="w-full md:w-1/2">
              <div className="flex flex-col gap-12">
                {/* Block */}
                <div className="flex flex-col gap-4 text-center md:text-left">
                  <h3 className="text-2xl md:text-3xl font-bold">Conversational AI </h3>
                  <p className="text-lg">Connect your AI to leading platforms, AI assistants, and messaging apps to drive more engagement and visibility.</p>
                  {/* <a href="#" className="hover:text-primary font-semibold">Learn More &rarr;</a> */}
                  <div className="grid grid-cols-3 gap-6 mt-4 items-center">
                    <img 
                      src="https://res.cloudinary.com/fetch-ai/image/upload/v1740480057/flockx-website/Landing%20page/developer/whatsapp_n7xpe2.svg"
                      alt="AI Agent Whatsapp Integration Platform"
                      className="w-full h-auto mx-auto"
                    />
                    <img 
                      src="https://res.cloudinary.com/fetch-ai/image/upload/v1738929450/flockx-website/Landing%20page/developer/discord_kb5x13.svg"
                      alt="AI Agent Discord Integration Platform"
                      className="w-full h-auto mx-auto"
                    />
                    <img 
                      src="https://res.cloudinary.com/fetch-ai/image/upload/v1738929450/flockx-website/Landing%20page/developer/telegram_tv7mes.svg"
                      alt="AI Agent Telegram Integration Platform"
                      className="w-full h-auto mx-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="py-16 px-4 md:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col md:flex-row gap-8 md:gap-24">
            {/* Left Column - 1/3 width */}
            <div className="w-full md:w-1/2 flex items-center justify-center md:justify-start">
              <div className="mb-2 md:mb-4 text-center md:text-left">
                <h2 className="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold pb-2">
                Universal LLM Access
                </h2>
               <p className="text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-4 md:mb-8 leading-7 md:leading-8 lg:leading-9">
                Connect your AI Agent to leading LLMs for deeper insights, faster responses, and expanded knowledge. Stay ahead by tapping into the best models available.           </p>
              </div>
            </div>

            {/* Right Column - 2/3 width */}
         
            <div className="w-full md:w-1/2">
              <div className="grid grid-cols-2 gap-6">
                <div className="flex items-center justify-center">
                  <img 
                    src="https://res.cloudinary.com/fetch-ai/image/upload/v1740480948/flockx-website/Landing%20page/developer/deepseek-logo-l-sm_j3pqvv.svg"
                    alt="Deepseek AI LLM Integration for AI Agents"
                    className="w-3/4 h-auto mx-auto"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <img 
                    src="https://res.cloudinary.com/fetch-ai/image/upload/v1740480948/flockx-website/Landing%20page/developer/meta-logo-l-sm_fb5iso.svg"
                    alt="Meta AI LLM Integration for AI Agents"
                    className="w-3/4 h-auto mx-auto"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <img 
                    src="https://res.cloudinary.com/fetch-ai/image/upload/v1740480949/flockx-website/Landing%20page/developer/asi-logo-l-sm_i2rjgx.svg"
                    alt="ASI-1 Mini LLM Integration for AI Agents"
                    className="w-3/4 h-auto mx-auto"
                  />
                </div>
                
                <div className="flex items-center justify-center">
                  <img 
                    src="https://res.cloudinary.com/fetch-ai/image/upload/v1740480947/flockx-website/Landing%20page/developer/claude-logo-l-sm_ggwc4z.svg"
                    alt="Claude AI LLM Integration for AI Agents"
                    className="w-3/4 h-auto mx-auto"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <img 
                    src="https://res.cloudinary.com/fetch-ai/image/upload/v1740480947/flockx-website/Landing%20page/developer/gpt-logo-l-sm_ex86dm.svg"
                    alt="ChatGPT LLM Integration for AI Agents"
                    className="w-3/4 h-auto mx-auto"
                  />
                </div>
                <div className="flex items-center justify-center">
                  <img 
                    src="https://res.cloudinary.com/fetch-ai/image/upload/v1740480947/flockx-website/Landing%20page/developer/gemeni-l-sm_vosssh.svg"
                    alt="Google Gemini LLM Integration for AI Agents"
                    className="w-3/4 h-auto mx-auto"
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>






{/* Simulator section */}
      <section className="py-16 md:py-32 px-4 md:px-6 lg:px-8 darker-grey">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col md:flex-row gap-12 md:gap-24">
            
            {/* Right Column - Existing Content */}
            <div className="w-full md:w-1/2 flex items-center order-1 md:order-2">
              <div className="mb-4 text-center md:text-left">
                <h2 className="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold pb-2">
                Open Agent <br></br> Collaboration Sessions
                </h2>
                <p className="text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-6 md:mb-8 leading-7 md:leading-8 lg:leading-9">
                Test and improve your Agent with multi-agent sessions that helps your agent get you conversions and insights.
                </p>
              </div>
            </div>

            {/* Left Column - Simulator Interface */}
            <div className="w-full md:w-1/2 order-2 md:order-1">
              <div className="rounded-lg p-6 border border-primary">
                <h3 className="text-xl mb-6">Simulator</h3>
                       
                {/* Meta Agent Message */}
                <div className="flex items-center gap-3 mb-4 mr-20">
                  <div className="bg-[#7857FF] w-8 h-8 rounded flex items-center justify-center">
                    <span className="text-xs font-medium">MA</span>
                  </div>
                  <span>Meta Agent</span>
                </div>
                
                {/* Message Input */}
                <div className="mr-20 bg-[#262631] rounded-lg p-6 border border-primary p-3 mb-4">
                  <p>I want to book flights to Milan for 2</p>
                </div>

                {/* Experience Search Agent Response */}
                <div className="flex items-center gap-3 mb-4 ml-20">
                  <div className="w-8 h-8">
                    <img 
                      src="https://res.cloudinary.com/fetch-ai/image/upload/v1738682726/flockx-website/Landing%20page/developer/airbnb-logo_hlfbhg.svg" 
                      alt="AI Agent Simulator Experience Search Integration"
                      className="w-full h-full"
                    />
                  </div>
                  <span>Experience Search Agent</span>
                </div>
                
                {/* Agent Response */}
                <div className="ml-20 bg-[#262631] rounded-lg p-6 border border-primary p-3">
                  <p>Agent reached.</p>
                </div>

              </div>

            </div>

          </div>
        </div>
      </section>



{/* Low Code Options Section */}
<section className="py-24 px-4 md:px-6 lg:px-8 darker-grey">
  <div className="max-w-7xl mx-auto">
    <h2 className="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-bold text-center mb-6">
      Low Code and No Code Options Available
    </h2>

    <div className="grid grid-cols-3 gap-4 md:gap-8 mt-16">
      {/* Developers Card */}
      <a href="http://agents.flockx.io/" target='_blank' className="flex flex-col items-center justify-center p-3 md:p-10 rounded-lg border border-primary h-32 md:h-72 hover:bg-primary/10 transition-colors">
        <div className="mb-2 md:mb-5">
          <img 
            src="https://res.cloudinary.com/fetch-ai/image/upload/v1738679810/flockx-website/Landing%20page/developer/fetch-icon_hh1eiy.svg"
            alt="Developer Tools for AI Agent Development"
            className="w-8 h-8 md:w-16 md:h-16"
          />
        </div>
        <h3 className="text-sm md:text-xl lg:text-2xl font-semibold text-white text-center">
          Developers
        </h3>
      </a>

      {/* Fetch.ai SDK Card */}
        <a href="https://github.com/fetchai/fetchai" target="_blank" className="flex flex-col items-center justify-center p-3 md:p-10 rounded-lg border border-primary h-32 md:h-72 hover:bg-primary/10 transition-colors">
        <div className="mb-2 md:mb-5">
          <img 
            src="https://res.cloudinary.com/fetch-ai/image/upload/v1738679810/flockx-website/Landing%20page/developer/github_zfx4t7.svg"
            alt="Fetch.ai SDK Integration Tools"
            className="w-8 h-8 md:w-16 md:h-16"
          />
        </div>
        <h3 className="text-sm md:text-xl lg:text-2xl font-semibold text-white text-center">
          Fetch.ai SDK
        </h3>
      </a>

      {/* Mobile App Card */}
        <a href="http://community.flockx.io/" target="_blank" className="flex flex-col items-center justify-center p-3 md:p-10 rounded-lg border border-primary h-32 md:h-72 hover:bg-primary/10 transition-colors">
        <div className="mb-2 md:mb-5">
          <img 
            src="https://res.cloudinary.com/fetch-ai/image/upload/v1738679810/flockx-website/Landing%20page/developer/flockx_bqd8lf.svg"
            alt="Flockx Mobile App Development Platform"
            className="w-8 h-8 md:w-16 md:h-16"
          />
        </div>
        <h3 className="text-sm md:text-xl lg:text-2xl font-semibold text-white text-center">
          Mobile App
        </h3>
      </a>
        </div>
      </div>
    </section>

       {/* FetchBlocks Section */}
     <section className="py-24 px-4 md:px-6 lg:px-8 ">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold text-center mb-6 md:mb-8 text-white">
          Powered by <a href="http://fetch.ai/">Fetch.ai</a>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-2 mb-12">
            {fetchBlocks.map((block, index) => (
              <a 
                key={index} 
                href={block.href}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col max-w-sm p-6 darker-grey rounded-2xl shadow-xl hover:bg-[#363945] transition-colors"
              >
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center">
                    <img 
                      src={block.block_img}
                      alt={block.alt}
                      className="w-12 h-12 rounded-full mr-4"
                    />
                    <div>
                      <h3 className="text-lg font-semibold text-white">{block.title}</h3>
                    </div>
                  </div>
                </div>
                <p className="text-gray-300">{block.description}</p>
              </a>
            ))}
          </div>

          <div className="flex justify-center">
            <Button
              label="Create your own AI Agent"
              onClickHandler={handleCTAButtonClick}
              id="Create AI"
              className="!bg-primary font-semibold hover:!bg-primary/90 !from-primary !to-primary !px-16"
              isSecondaryButton={false}
            />
          </div>
        </div>
      </section>


      <section className="py-32 px-4 md:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col md:flex-row gap-24">
            {/* Left Column - 1/3 width */}
            <div className="w-full md:w-1/2 flex items-center justify-center md:justify-start">
              <div className="mb-4 text-center md:text-left">
                <h2 className="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-bold pb-2">
                Web3 Batteries Included
                </h2>
                <p className="text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-6 md:mb-8 leading-7 md:leading-8 lg:leading-9">
                Wallet authentication and transaction out of the box.
                </p>
                <p className="text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-6 md:mb-8 leading-7 md:leading-8 lg:leading-9">
                Connect to the leading Decentralized AI Network, Fetch AI, connecting your AI agent to an autonomous economy that drives business to your agent.</p>
              </div>
            </div>

            {/* Right Column - 2/3 width */}
         
            <div className="w-full md:w-1/2">
              <div className="flex flex-col items-center gap-4">
                <img 
                  src="https://res.cloudinary.com/fetch-ai/image/upload/v1738678692/flockx-website/Landing%20page/developer/Group_2085662503_d34mog.svg"
                  alt="Web3 AI Agent Integration Dashboard"
                  className="w-full max-w-xl"
                />
              </div>
            </div>

          </div>
        </div>
      </section>


  {/* Chat AIs Section */}
  <section className="py-24 px-4 md:px-6 lg:px-8 darker-grey">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-semibold text-center pb-2">
          Connect Your AI Agent Today
          </h2>
            <p className="text-center text-base md:text-lg lg:text-xl 2xl:text-2xl mb-6 md:mb-8 leading-7 md:leading-8 lg:leading-9 max-w-4xl mx-auto px-4">
            flockx is a leading contributor of Open Source tools for agent discovery and collaboration.
          </p>
          
          <div className="flex justify-center mt-8">
            <Button
              label="Get Started &rarr;"
              onClickHandler={handleCTAButtonClick}
              id="Get Started"
              className="!bg-primary font-bold hover:!bg-primary/90 !from-primary !to-primary transition-colors !px-16"
              isSecondaryButton={false}
            />
          </div>

        </div>
      </section>



{/* We Love Open Source Section */}
<section className="py-24 px-4 md:px-6 lg:px-8 darker-grey">
  <div className="max-w-7xl mx-auto">
    <div className="max-w-5xl mx-auto bg-[#2C2E38] rounded-2xl p-8 md:p-16">
      <h2 className="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-bold text-center mb-4 max-w-full">
        We Love Open Source
      </h2>
      <p className="text-center text-base md:text-lg lg:text-xl 2xl:text-2xl text-gray-300 mb-16 max-w-full">
      flockx is a leading contributor of Open Source tools for agent discovery and collaboration.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-4xl mx-auto">
        {/* SDK Card */}
        <div className="flex flex-col items-start text-left gap-4">
          <div className="w-16 h-16">
            <img 
              src="https://res.cloudinary.com/fetch-ai/image/upload/v1738327462/flockx-website/Landing%20page/knowledge/agentverse_kr6p1i.svg"
              alt="Fetch.ai SDK Open Source Development Tools"
              className="w-full h-full"
            />
          </div>
          <div> <p className="text-gray-300 text-lg">
            <a href="https://github.com/fetchai/fetchai" className="text-primary hover:text-primary/80font-semibold">
              fetchai sdk &nbsp;
            </a>
             easy mode for connecting your agents to other agents.
            </p>
          </div>
        </div>

        {/* Open Agent Framework Card */}
        <div className="flex flex-col items-start text-left gap-4">
          <div className="w-16 h-16">
            <img 
              src="https://res.cloudinary.com/fetch-ai/image/upload/v1738684436/flockx-website/Landing%20page/developer/chat_onb78p.svg"
              alt="Open Agent Framework Icon"
              className="w-full h-full"
            />
          </div>
          <div> <p className="text-gray-300 text-lg">
            <a href="https://github.com/fetchai/fetchai" className="text-primary hover:text-primary/80 font-semibold">
              Open Agent Collaboration Framework &nbsp;
            </a>
           
             open specifications for connecting agents.
            </p>
          </div>
        </div>
      </div>

      <p className="text-center text-xl text-gray-300 mt-16">
      We contribute to the ASI Network powered by fetch.ai open source tools.
      </p>
    </div>
  </div>
</section>




    </BaseLayout>
  );
}

export const query = graphql`
  query ActivityHomePageQuery {
    site {
      siteMetadata {
        description
        siteUrl
      }
    }
  }
`;
