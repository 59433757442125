import React from 'react';
import TwitterIcon from '../atoms/icons/TwitterIcon';
import TikTokIcon from '../atoms/icons/TikTokIcon';
import LinkedInIcon from '../atoms/icons/LinkedInIcon';
import InstagramIcon from '../atoms/icons/InstagramIcon';


export default function SiteSocials() {
  return (
    <div className='flex gap-5 justify-between align-center  dark-version'>
      <a
        href='https://twitter.com/flockxofficial?s=20&t=7XFX2wadEO2PhOp6qM4ZGA'
        target='_blank'
      >
        <TwitterIcon />
      </a>

      <a href='https://www.tiktok.com/@flockxofficial' target='_blank'>
        <TikTokIcon />
      </a>
   
      <a href='https://www.instagram.com/flockxofficial/' target='_blank' className='dark-version'>
        <InstagramIcon />
      </a>

      <a
        href='https://www.linkedin.com/search/results/all/?heroEntityKey=urn%3Ali%3Aorganization%3A89241529&keywords=flockx&origin=RICH_QUERY_TYPEAHEAD_HISTORY&position=0&searchId=e0b6a56f-3140-4ded-a5af-a2fb9d6ceec2&sid=fK.'
        target='_blank'
      >
        <LinkedInIcon />
      </a>
    </div>
  );
}
